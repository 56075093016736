<template>
    <b-container fluid>
        <b-row>
            <b-col class="pl-0">
                <b-button variant="link" @click="save()">Сохранить</b-button>
                <b-button variant="link" @click="$router.go(-1)">Закрыть</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="3" md="4" cols="12">
                <b-form-group label="Название">
                    <b-form-input v-model="entity.label"/>
                </b-form-group>
            </b-col>
            <b-col xl="3" md="4" cols="12">
                <b-form-group label="Логин">
                    <b-form-input v-model="entity.login"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="3">
                <b-col cols="12">
                    <b-form-group label="Имя">
                        <b-form-input  v-model="entity.address.name"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Компания">
                        <b-form-input  v-model="entity.address.company"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Телефон">
                        <b-form-input  v-model="entity.address.phone"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Телефон 2">
                        <b-form-input  v-model="entity.address.phone2"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Эл. почта">
                        <b-form-input  v-model="entity.address.email"/>
                    </b-form-group>
                </b-col>
            </b-col>
            <b-col cols="12" md="3">
                <b-col cols="12">
                    <b-form-group label="Индекс">
                        <b-form-input  v-model="entity.address.zipcode"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Страна">
                        <b-form-input  v-model="entity.address.country"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Регион">
                        <b-form-input  v-model="entity.address.region"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Город">
                        <b-form-input  v-model="entity.address.city"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Район">
                        <b-form-input  v-model="entity.address.area"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Населённый пункт">
                        <b-form-input  v-model="entity.address.settlement"/>
                    </b-form-group>
                </b-col>
            </b-col>
            <b-col cols="12" md="3">
                <b-col cols="12">
                    <b-form-group label="Улица">
                        <b-form-input  v-model="entity.address.street"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Дом">
                        <b-form-input  v-model="entity.address.house"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Корпус">
                        <b-form-input  v-model="entity.address.block"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Строение">
                        <b-form-input  v-model="entity.address.building"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Квартира/Офис">
                        <b-form-input  v-model="entity.address.flat"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Замена для адреса">
                        <b-form-input  v-model="entity.address.replacement"/>
                    </b-form-group>
                </b-col>
            </b-col>
            <b-col cols="12" md="3">
                <b-col cols="12">
                    <b-form-group label="ФИАС">
                        <b-form-input  v-model="entity.address.fiasCode"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="КЛАДР">
                        <b-form-input  v-model="entity.address.kladrCode"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="ФИАС города">
                        <b-form-input  v-model="entity.address.cityFiasCode"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="ФИАС Региона">
                        <b-form-input  v-model="entity.address.regionFiasCode"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="ФИАС Района">
                        <b-form-input  v-model="entity.address.areaFiasCode"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="ФИАС населённого пункта">
                        <b-form-input  v-model="entity.address.settlementFiasCode"/>
                    </b-form-group>
                </b-col>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "SortingCenterEditor",
        created() {
            if (this.$route.params.id) {
                this.getEntity(this.$route.params.id);
            }
        },
        data() {
            return {
                entity: {
                    id: null,
                    label: null,
                    login: null,
                    address: {
                        zipcode: null,
                        country: null,
                        region: null,
                        city: null,
                        area: null,
                        settlement: null,
                        street: null,
                        house: null,
                        block: null,
                        building: null,
                        flat: null,
                        replacement: null,
                        fiasCode: null,
                        kladrCode: null,
                        cityFiasCode: null,
                        regionFiasCode: null,
                        areaFiasCode: null,
                        settlementFiasCode: null,
                    },
                },
            }
        },
        methods: {
            save() {
                this.$http.post(this.$root.apiUrl + '/api/sorting-center', this.entity, {headers: this.$root.getHeaders()})
                    .then(response => {
                        if (response.body.success) {
                            this.$router.push("/sorting-centers");
                        } else {
                            alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                        }
                    }, response => {
                        this.$root.handleErrorResponse(response);
                    });
            },
            getEntity(id) {
                this.$http.get(this.$root.apiUrl + '/api/sorting-center/' + id, {headers: this.$root.getHeaders()})
                    .then(response => {
                            if (response.body.success) {
                                this.entity = response.body.entity;
                            } else {
                                alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                            }
                        },
                        response => {
                            this.$root.handleErrorResponse(response);
                        }
                    );
            },
        }
    }
</script>

<style scoped>
</style>